'use client'

import { RiCloseCircleLine } from '@remixicon/react'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useTranslation } from 'react-i18next'
import useTable from './useTable'

export function DataTableToolbar() {
  const table = useTable()
  const { t } = useTranslation()

  if (!table) return null

  const isFiltered = table.getState().columnFilters.length > 0

  const filterInputs = table
    .getAllColumns()
    .filter(column => (column.columnDef?.meta as { filterVariant?: 'text' })?.filterVariant === 'text')
    .map(column => {
      return (
        <Input
          key={column.id}
          placeholder={t('data_table.filter-by')}
          onChange={event => {
            column.setFilterValue(event.target.value)
          }}
          value={(column.getFilterValue() as string) ?? ''}
          className='h-8 w-[250px] lg:w-[350px]'
        />
      )
    })

  return (
    <div className='flex items-center justify-between'>
      <div className='flex flex-1 items-center space-x-2'>
        {filterInputs}

        {isFiltered && (
          <Button
            variant='ghost'
            onClick={() => {
              table.resetColumnFilters()
            }}
            className='h-8 px-2 lg:px-3'
          >
            {t('data_table.reset')}
            <RiCloseCircleLine className='ml-2 h-4 w-4' />
          </Button>
        )}
      </div>
      {/* <DataTableViewOptions table={table} /> */}
    </div>
  )
}
