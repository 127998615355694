import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { useKeycloak } from '@/hooks/useKeycloak'
import { cn } from '@/lib/utils'
import React, { ComponentPropsWithoutRef, ElementRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ListItem = React.forwardRef<ElementRef<'a'>, ComponentPropsWithoutRef<'a'>>(
  // eslint-disable-next-line react/prop-types
  ({ className, title, ...props }, ref) => {
    return (
      <DropdownMenuItem asChild>
        <a
          ref={ref}
          className={cn(
            'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
            className
          )}
          {...props}
        >
          <div className='text-sm font-medium leading-none'>{title}</div>
          {/* <p className='line-clamp-2 text-sm leading-snug text-muted-foreground'>
						{children}
					</p> */}
        </a>
      </DropdownMenuItem>
    )
  }
)
ListItem.displayName = 'ListItem'

export const MeMenu = () => {
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const keycloak = useKeycloak()
  useEffect(() => {
    if (keycloak)
      keycloak?.loadUserInfo().then(user => {
        setName((user as { name: string }).name)
      })
  }, [keycloak])

  const accountUrl = `${keycloak?.authServerUrl}realms/${keycloak?.realm}/account/`

  const meMenyItems = [
    {
      title: t('nav.my-account', 'My Account'),
      href: accountUrl
    },
    {
      title: t('nav.logout', 'Logout'),
      href: keycloak?.createLogoutUrl()
    }
  ]

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>{name}</DropdownMenuTrigger>
      <DropdownMenuContent>
        {meMenyItems.map(item => (
          <ListItem key={item.title} title={item.title} href={item.href} />
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
