import { useTranslation } from 'react-i18next'
import {
  DashboardIcon,
  FacilityIcon,
  PowershareIcon,
  SingleBoardComputerIcon,
  UserIcon
} from '../Icons/Icons'

import { RiDashboard2Fill } from '@remixicon/react'

export type Nav = {
  title: string
  Icon?: typeof RiDashboard2Fill
  children?: Nav[]
  path?: string
  beta?: boolean
}

export const useNav = () => {
  const { t } = useTranslation()

  const nav: Nav[] = [
    {
      title: t('nav.dashboard'),
      Icon: DashboardIcon,
      path: '/',
      beta: true
    },
    {
      title: t('nav.facilities'),
      Icon: FacilityIcon,
      path: '/facilities',
      beta: true
    },
    {
      title: t('nav.powershare-management'),
      Icon: PowershareIcon,
      path: '/powershares'
    },

    {
      title: t('nav.user-management'),
      Icon: UserIcon,
      path: '/users'
    },
    {
      title: t('nav.single-board-computers'),
      Icon: SingleBoardComputerIcon,
      path: '/single-board-computers'
    }
  ]
  return nav
}
