import { Toaster } from '@/components/ui/toaster'
import { Outlet } from '@tanstack/react-router'
import { useState } from 'react'
import { BottomNav } from './BottomNav'
import { SiteHeader } from './Header'
import { SideBar } from './SideBar'

export const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <div className='bg-background'>
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className='flex h-screen overflow-hidden'>
        {/* <!-- ===== Sidebar Start ===== --> */}
        <SideBar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className='relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden'>
          {/* <!-- ===== Header Start ===== --> */}
          <SiteHeader setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            <div className='mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10'>
              <Outlet />
            </div>
          </main>
          <Toaster />
          {/* <!-- ===== Main Content End ===== --> */}
          <BottomNav />
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  )
}
