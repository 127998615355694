import { Separator } from '@/components/ui/separator'
import { RiArrowDropDownLine } from '@remixicon/react'
import { Link } from '@tanstack/react-router'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FeedbackButton } from '../FeedbackButton/FeedbackButton'
import { Logo } from '../Icons/Icons'
import { Nav, useNav } from './useNav'

const listItem = (
  { title, Icon, path, children, beta }: Nav,
  {
    setSidebarExpanded,
    sidebarExpanded,
    sidebarOpen,
    setSidebarOpen
  }: {
    setSidebarExpanded: React.Dispatch<React.SetStateAction<string | null>>
    sidebarExpanded: string | null
    sidebarOpen: boolean
    setSidebarOpen: (arg: boolean) => void
  }
) => {
  return (
    <li key={title}>
      <Link
        to={path ? path : '#'}
        activeProps={{ className: 'text-primary' }}
        className={'group flex items-center rounded-lg p-2 text-card-foreground hover:bg-muted'}
        onClick={e => {
          if (children) {
            e.preventDefault()
            sidebarExpanded == title ? setSidebarExpanded(null) : setSidebarExpanded(title)
          }
          if (path) {
            setSidebarOpen(!sidebarOpen)
          }
        }}
      >
        {Icon ? <Icon /> : ''}
        <span className='ms-3 flex-1 whitespace-nowrap'>{title}</span>
        {beta ? (
          <span className='ms-3 inline-flex items-center justify-center rounded-full bg-accent px-2 text-xs font-medium text-card-foreground'>
            BETA
          </span>
        ) : (
          ''
        )}
        {children ? (
          <span className='ms-3 inline-flex items-center justify-center rounded-full px-2 text-sm font-medium text-card-foreground'>
            <RiArrowDropDownLine />
          </span>
        ) : (
          ''
        )}
      </Link>

      {children ? (
        <ul className={(sidebarExpanded == title ? '' : 'hidden') + 'space-y-2 font-medium'}>
          {children.map(items => {
            if (items.title) {
              return listItem(items, {
                setSidebarExpanded,
                sidebarExpanded,
                sidebarOpen,
                setSidebarOpen
              })
            } else return ''
          })}
        </ul>
      ) : (
        ''
      )}
    </li>
  )
}

interface SidebarProps {
  sidebarOpen: boolean
  setSidebarOpen: (arg: boolean) => void
}

const SideBar = ({ sidebarOpen, setSidebarOpen }: SidebarProps): ReactElement => {
  const { t } = useTranslation()
  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')

  const [sidebarExpanded, setSidebarExpanded] = useState<string | null>(storedSidebarExpanded)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trigger = useRef<any>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sidebar = useRef<any>(null)

  const nav = useNav()

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!sidebar.current || !trigger.current) return
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return
      setSidebarOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!sidebarOpen || keyCode !== 27) return
      setSidebarOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded || '')
    if (sidebarExpanded) {
      document.querySelector('body')?.classList.add('sidebar-expanded')
    } else {
      document.querySelector('body')?.classList.remove('sidebar-expanded')
    }
  }, [sidebarExpanded])

  return (
    <>
      <aside
        ref={sidebar}
        id='default-sidebar'
        className={`w-72.5 absolute left-0 top-0 z-50 flex h-screen -translate-x-full flex-col overflow-y-hidden bg-card duration-300 ease-linear lg:static lg:translate-x-0 ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
        aria-label='Sidebar'
      >
        <div className='py-5.5 lg:py-6.5 flex items-center justify-between gap-2 px-6 pt-10'>
          <Link to='/' className='m-auto'>
            <Logo className='h-12' />
          </Link>

          <button
            ref={trigger}
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls='sidebar'
            aria-expanded={sidebarOpen}
            className='block lg:hidden'
          >
            <svg
              className='fill-current'
              width='20'
              height='18'
              viewBox='0 0 20 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z'
                fill=''
              />
            </svg>
          </button>
        </div>

        <div className='no-scrollbar flex h-full flex-col overflow-y-auto bg-card px-3 py-4'>
          <nav className='mt-5 px-4 py-4 lg:mt-9 lg:px-6'>
            {/* <!-- Menu Group --> */}
            <div>
              <h3 className='mb-4 ml-4 text-sm font-semibold text-card-foreground'>
                {t('nav.menu', 'MENU')}
              </h3>
              <ul className='space-y-2 font-medium'>
                {nav.map(items =>
                  listItem(items, {
                    setSidebarExpanded,
                    sidebarExpanded,
                    sidebarOpen,
                    setSidebarOpen
                  })
                )}
              </ul>
            </div>
          </nav>
          <div className='flex grow flex-col'></div>
          <div className='mt-5 px-4 py-4 lg:mt-9 lg:px-6'>
            <FeedbackButton />
          </div>
          <Separator className='bg-muted' />

          <ul className='s space-y-2 font-medium text-muted-foreground'>
            <li>
              {t('common.version')}: {__APP_VERSION__}
            </li>
            <li>
              {t('common.date')}: {__APP_BUILD_DATE__}
            </li>
          </ul>
        </div>
      </aside>
    </>
  )
}

export { SideBar }
