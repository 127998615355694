import { RiMailSendLine } from '@remixicon/react'
import { useTranslation } from 'react-i18next'

export const FeedbackButton = () => {
  const { t } = useTranslation()

  const to = 'fsp-feedback@ferroamp.se'
  const subject = 'Ferroamp Service Portal Feedback'
  const body = `


[Write your message here] 👆

Please feel free to attach screenshots or recordings 😊

--- ⛔ Please do not remove the following ⛔ ---
agent:${navigator.userAgent};Version:${__APP_VERSION__};BuildDate:${__APP_BUILD_DATE__};browserLanguage:${navigator.language}

`
  return (
    <a
      className='group flex items-center rounded-lg p-2 text-card-foreground hover:bg-muted'
      href={encodeURI(`mailto:${to}?subject=${subject}&body=${body}`)}
    >
      <RiMailSendLine className='mr-2' />
      {t('common.send-feedback')}
    </a>
  )
}
