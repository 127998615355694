import { ModeToggle } from '@/components/Navigation/ModeToggle'
import { Button } from '@/components/ui/button'
import { LocaleSwitch } from '@/i18n/LocaleSwitch'
import { useCurrentFacility } from '@/store/useCurrentFacility'
import { RiMenu2Line } from '@remixicon/react'
import { useLocation } from '@tanstack/react-router'
import { AnimatePresence, motion } from 'framer-motion'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { MeMenu } from './MeMenu'
interface SideHeaderProps {
  sidebarOpen: boolean
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
}
export function SiteHeader({ setSidebarOpen, sidebarOpen }: SideHeaderProps) {
  const currentFacility = useCurrentFacility(s => s.facility)
  const setFacility = useCurrentFacility(s => s.setFacility)
  const [isFacility, setIsFacility] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const isIt = /\/facilities\/.+/.test(location.pathname)
    setIsFacility(!!isIt)
    if (!isIt) setFacility(null)
  }, [location, currentFacility, setFacility])

  return (
    <header className='sticky top-0 z-40 w-full bg-card/95 backdrop-blur supports-[backdrop-filter]:bg-background/60'>
      <div className='flex h-14 w-full items-center px-3 py-3 lg:px-5 lg:pl-3'>
        <div className='hidden md:block lg:hidden'>
          <Button
            variant='ghost'
            aria-controls='sidebar'
            onClick={e => {
              e.stopPropagation()
              setSidebarOpen(!sidebarOpen)
            }}
            className='invisible md:visible lg:invisible'
          >
            <RiMenu2Line className='text-card-foreground' />
          </Button>
        </div>
        <AnimatePresence>
          {isFacility && currentFacility && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ease: 'easeIn', stiffness: 100 }}
              exit={{ opacity: 0 }}
              className='text-lg text-foreground'
            >
              {currentFacility?.name} ({currentFacility?.id})
            </motion.p>
          )}
        </AnimatePresence>

        <div className='flex flex-1 items-center justify-between space-x-2 md:justify-end'>
          <nav className='flex items-center gap-6 text-sm text-foreground'>
            <MeMenu />
            <LocaleSwitch />
            <ModeToggle />
          </nav>
        </div>
      </div>
    </header>
  )
}
