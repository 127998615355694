import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { useTranslation } from 'react-i18next'
import { supportedLngs } from './i18n'

export const LocaleSwitch = () => {
  const { t, i18n } = useTranslation()
  const availableLanguages: {
    [Key in (typeof supportedLngs)[number]]: string
  } = {
    en: t('language.english', 'English'),
    sv: t('language.swedish', 'Swedish'),
    klingon: 'Klingon'
  }

  const setLanguage = (language: (typeof supportedLngs)[number]) => {
    i18n.changeLanguage(language)
  }

  const current = availableLanguages[(i18n.resolvedLanguage as (typeof supportedLngs)[number]) || 'en']
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant='outline'>
          {current}
          <span className='sr-only'>{t('language.sr.toggle-language', 'Toggle language')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end'>
        {Object.entries(availableLanguages).map(([key, value]) => (
          <DropdownMenuItem
            className='cursor-pointer p-2'
            key={key}
            onClick={() => setLanguage(key as (typeof supportedLngs)[number])}
          >
            {value}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
