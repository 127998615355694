import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { cn } from '@/lib/utils'
import { Link, LinkProps } from '@tanstack/react-router'
import { HTMLAttributes, forwardRef } from 'react'

export type PageHeaderChildPages = {
  link: LinkProps
  name: string
}[]

interface PageHeaderProps {
  childPages: PageHeaderChildPages
}

/**
 * Component to be used in top of header, for be used for introducing several sub-pages for a main page.
 */
export const PageHeader = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & PageHeaderProps>(
  // eslint-disable-next-line react/prop-types
  ({ className, childPages = [], ...props }, ref) => {
    return (
      <>
        <Card
          className={cn(
            'sticky top-0 z-40 hidden w-full rounded-none bg-transparent shadow-none lg:block',
            className
          )}
          ref={ref}
          {...props}
        >
          <CardContent className='overflow-x-auto p-4 pt-2'>
            <div className='flex'>
              {childPages
                ? childPages.map(({ link, name }, index) => {
                    return (
                      <Link key={`name_${index}`} {...link} className='ml-4'>
                        <Button variant='outline' className='min-w-40'>
                          {name}
                        </Button>
                      </Link>
                    )
                  })
                : null}
            </div>
          </CardContent>
        </Card>

        <div className='flex flex-col lg:hidden'>
          {childPages
            ? childPages.map(({ link, name }, index) => {
                return (
                  <Link key={`name_${index}`} {...link} className='mb-4'>
                    <Button variant='outline' className='w-full text-white'>
                      {name}
                    </Button>
                  </Link>
                )
              })
            : null}
        </div>
      </>
    )
  }
)

PageHeader.displayName = 'PageHeader'
