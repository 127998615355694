import { DataTable } from '@/components/DataTable'
import TBody from '@/components/DataTable/TBody'
import THead from '@/components/DataTable/THead'
import { PageHeader } from '@/components/PageHeader'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import usePowershareTable from '@/hooks/usePowershareTable'
import { createFileRoute } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

export const powersharesSearchSchema = z.object({
  pagination: z
    .object({
      take: z.number().min(0).default(30),
      skip: z.number().min(0).default(0)
    })
    .default({}),
  orderBy: z
    .object({
      id: z.string().default('id'),
      desc: z.boolean().default(false)
    })
    .array()
    .default([]),
  where: z
    .object({
      id: z.string(),
      value: z.string()
    })
    .array()
    .default([])
})

export const Route = createFileRoute('/powershares/')({
  component: PowershareListComponent,
  validateSearch: powersharesSearchSchema,
  pendingComponent: () => <div>Loading component...</div>
})

function PowershareListComponent() {
  const { t } = useTranslation()
  const { table, fetching, error } = usePowershareTable()

  return (
    <div className='flex flex-col gap-4'>
      <PageHeader
        childPages={[
          {
            name: t('powershare.create.create-powershare', 'Create Powershare'),
            link: { to: '/powershares/create' }
          }
        ]}
      />
      <Card>
        <CardHeader>
          <CardTitle>{t('powershare.powershares', 'Powershares')}</CardTitle>
        </CardHeader>
        <CardContent>
          <DataTable showPagination={true} fetching={fetching} error={error} table={table}>
            <THead />
            <TBody />
          </DataTable>
        </CardContent>
      </Card>
    </div>
  )
}
