import { Link } from '@tanstack/react-router'
import { useNav } from './useNav'

export const BottomNav = () => {
  const nav = useNav()

  return (
    <>
      <div className='flex w-full bg-background sm:hidden'>
        <div className='h-24'></div>
      </div>
      <div className='fixed bottom-0 z-50 w-full bg-background pl-4 pr-4 sm:hidden sm:gap-4'>
        <div className='mb-4 flex w-full justify-between p-4 text-muted-foreground'>
          {nav.map(({ Icon, path }, index) => (
            <Link key={index} to={path} activeProps={{ className: 'text-card-foreground' }}>
              {({ isActive }) => (
                <>
                  {Icon ? <Icon size={24} /> : null}
                  <div
                    className={`${isActive ? '' : 'invisible'} m-auto mt-2 h-2 w-2 rounded bg-foreground`}
                  ></div>
                </>
              )}
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}
