// type IconProps = React.HTMLAttributes<SVGElement>
import { useTheme } from '@/theme'
import {
  RiAlertLine,
  RiArrowDropDownLine,
  RiArrowDropLeftLine,
  RiArrowDropRightLine,
  RiCheckLine,
  RiCheckboxCircleLine,
  RiCircleLine,
  RiCloseLine,
  RiCommunityLine,
  RiComputerLine,
  RiDashboardLine,
  RiErrorWarningLine,
  RiHome2Line,
  RiInformationLine,
  RiMoonLine,
  RiRefreshLine,
  RiSunLine,
  RiUserLine
} from '@remixicon/react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ImageProps = React.ImgHTMLAttributes<any>

export const Logo = (props: ImageProps) => {
  const { theme } = useTheme()

  const isTheme =
    theme === 'system'
      ? window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light'
      : theme

  return (
    <>
      <img {...props} src={`/images/logo_${isTheme === 'dark' ? 'white' : 'black'}.png`}></img>
    </>
  )
}

/**
 * Shadcn defaults to lucide-react icons, these are mapping the lucide-react icon names to
 * remix-icon name to easily and fast replace them
 */
export {
  RiCheckLine as Check,
  RiArrowDropDownLine as ChevronDown,
  RiArrowDropLeftLine as ChevronLeft,
  RiArrowDropRightLine as ChevronRight,
  RiArrowDropDownLine as ChevronUp,
  RiCircleLine as Circle,
  RiMoonLine as Moon,
  RiSunLine as Sun,
  RiCloseLine as X
}

export const NotificationWarning = RiAlertLine
export const NotificationSuccess = RiCheckboxCircleLine
export const NotificationError = RiErrorWarningLine
export const NotificationInformation = RiInformationLine
export const NotificationNeutral = RiRefreshLine

export const DashboardIcon = RiDashboardLine
export const UserIcon = RiUserLine
export const PowershareIcon = RiCommunityLine
export const FacilityIcon = RiHome2Line
export const SingleBoardComputerIcon = RiComputerLine
